import React from 'react';
import './MaintencePage.css';

function MaintencePage() {
  return (
    <>
      <h1>This page is currently on maintence</h1>
    </>
  );
}

export default MaintencePage;